exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bio-js": () => import("./../../../src/pages/bio.js" /* webpackChunkName: "component---src-pages-bio-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-so-close-yet-so-far-js": () => import("./../../../src/pages/so-close-yet-so-far.js" /* webpackChunkName: "component---src-pages-so-close-yet-so-far-js" */),
  "component---src-pages-talk-about-home-js": () => import("./../../../src/pages/talk-about-home.js" /* webpackChunkName: "component---src-pages-talk-about-home-js" */),
  "component---src-pages-when-you-look-at-me-js": () => import("./../../../src/pages/when-you-look-at-me.js" /* webpackChunkName: "component---src-pages-when-you-look-at-me-js" */)
}

